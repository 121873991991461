@import "variable"

.select
  height: 38px !important
  font-size: 14px !important
  background: transparent !important

  *
    padding-left: 5px !important

    border-color: transparent !important

.status
  display: inline
  padding: 5px
  width: 79px
  text-align: center
  font-family: $fontSemiBold
  font-size: 12px
  border-radius: 4px
  text-transform: uppercase
  white-space: nowrap

  &_success
    @extend .status
    color: #4E9E41
    background-color: #D0F1CB

  &_info
    @extend .status
    color: #116ABD
    background-color: #DDEEFE

  &_warning
    @extend .status
    color: $colorWarning
    background-color: $colorWarningLight

  &_error
    @extend .status
    color: #CB281E
    background-color: #FFE3E1
    
  &_close
    @extend .status
    color: #4E9E41
    background-color: #D0F1CB
  