$fontRegular: Montserrat-Regular, sans-serif
$fontSemiBold: Montserrat-SemiBold, sans-serif
$fontBold: Montserrat-Bold, sans-serif
$fontBlack: Montserrat-Black, sans-serif

$colorPrimary: #2E97D5
$colorPrimaryLight: #F2FBFF
$colorPrimaryDark: #3082B3

$colorSecondary: #787878
$colorSecondaryLight: #E8E8E8

$colorBg: #F0F2FA

$colorWarning: #FFBB13
$colorWarningLight: #FFBB131B
$colorError: #FF0000
$colorErrorLight: #FCD0CF

$colorTitle: #01163E
$colorText: #5A6781

$colorHintLight: #BABABA

$boxShadow: 2px 2px 7px rgba(0, 0, 0, 0.1)

$breakpointSm: 600px
$breakpointMd: 900px
$breakpointLg: 1200px