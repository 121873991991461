@import "variable"

.title
  color: #171717!important
  font-feature-settings: 'clig' off, 'liga' off!important
  font-size: 30px!important
  font-style: normal!important
  font-weight: 700!important
  line-height: 34px!important
  letter-spacing: 1px!important
  margin-bottom: 10px!important

.subtitle
  color: #727272
  font-feature-settings: 'clig' off, 'liga' off
  font-size: 18px
  font-style: normal
  font-weight: 400
  line-height: 34px
  letter-spacing: 1px

.annual_container
  border: 1px solid #3898D3
  border-radius: 10px
  overflow: hidden

.annual_container_clearcheck
  border: 1px solid #3c64c3
  border-radius: 10px
  overflow: hidden

.annual_option
  display: flex
  min-width: 90px
  padding: 8px
  flex-direction: column
  justify-content: center
  align-items: center
  cursor: pointer
  border: none
  color: #3898D3
  font-size: 18px
  font-style: normal
  font-weight: 400
  line-height: normal
  &:hover
    opacity: 0.7
    background: #FFF
  &__active
    @extend .annual_option
    color: #FFF
    background: #3898D3
    border: none
    &:hover
      opacity: 1
      background: #3898D3
      cursor: default

.annual_option_clearcheck
  display: flex
  min-width: 90px
  padding: 8px
  flex-direction: column
  justify-content: center
  align-items: center
  cursor: pointer
  border: none
  color: #3c64c3
  font-size: 18px
  font-style: normal
  font-weight: 400
  line-height: normal
  &:hover
    opacity: 0.7
    background: #FFF
  &__active
    @extend .annual_option
    color: #FFF
    background: #3c64c3
    border: none
    &:hover
      opacity: 1
      background: #3c64c3
      cursor: default

.annual_pro_container
  border: 1px solid #03173D
  border-radius: 10px
  overflow: hidden

.annual_pro_option
  @extend .annual_option
  border: none
  color: #03173D
  &:hover
    opacity: 0.7
    background: #FFF
  &__active
    @extend .annual_pro_option
    background: #03173D
    border: none
    color: #FFF
    &:hover
      opacity: 1
      background: #03173D
      cursor: default

.main_section
  display: flex
  flex-flow: column
  gap: 50px
  padding: 32px

.pro_section
  @extend .main_section
  background: #FFF
  margin-top: 0!important
  padding-top: 50px


.main_container
  display: flex
  flex-direction: row
  flex-flow: row
  flex-wrap: wrap
  justify-content: center
  align-items: flex-start
  padding-top: 10px
  padding-bottom: 40px
  gap: 20px
