.social
  &__small
    &_callapp
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -10px -10px

    &_drupe
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -50px -10px

    &_eyecon
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -10px -50px

    &_fb,
    &_facebook
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -50px -50px

    &_getcontact
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -90px -10px

    &_hiya
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -90px -50px

    &_imo
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -10px -90px

    &_instagram
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -50px -90px

    &_linkedin
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -90px -90px

    &_ok
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -130px -10px

    &_skype
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -130px -50px

    &_tamtam
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -130px -90px

    &_botim
      width: 20px
      height: 20px
      background: url('../asset/icon/botim.png')

    &_luna
      width: 20px
      height: 20px
      background: url('../asset/icon/luna_s.png')
    
    &_taro
      width: 20px
      height: 20px
      background: url('../asset/icon/taro_s.png')
    
    &_kyc_iban
      width: 20px
      height: 20px
      background: url('../asset/icon/kyc_iban_s.png')
    
    &_kyc_company_enrich
      width: 20px
      height: 20px
      background: url('../asset/icon/kyc_company_enrich_s.png')
    
    &_kyc_vat_validate
      width: 20px
      height: 20px
      background: url('../asset/icon/kyc_vat_validate_s.png')
    
    &_web_collection_screenshot
      width: 20px
      height: 20px
      background: url('../asset/icon/web_collection_screenshot_s.png')
    
    &_web_collection_scrape
      width: 20px
      height: 20px
      background: url('../asset/icon/web_collection_scrape_s.png')
    
    &_geolocation
      width: 20px
      height: 20px
      background: url('../asset/icon/geolocation_s.png')
    
    &_web_collection_phone
      width: 20px
      height: 20px
      background: url('../asset/icon/web_collection_phone_s.png')
    
    &_viewcaller
      width: 20px
      height: 20px
      background: url('../asset/icon/viewcaller_s.png')

    &_caller_api
      width: 20px
      height: 20px
      background: url('../asset/icon/caller_api_s.png')

    &_kraken
      width: 20px
      height: 20px
      background: url('../asset/icon/caller_api_s.png')

    &_web_collection_email
      width: 20px
      height: 20px
      background: url('../asset/icon/web_collection_email_s.png')

    &_telegram
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -10px -130px

    &_tc
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -50px -130px

    &_twitter
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -90px -130px

    &_viber
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -130px -130px

    &_vk
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -170px -10px

    &_tiktok
      width: 20px
      height: 20px
      background: url('../asset/icon/tiktok.png') center no-repeat
      background-size: cover
    
    &_blsk
      width: 20px
      height: 20px
      background: url('../asset/icon/blsk_s.png') center no-repeat
      background-size: cover   
    
    &_sniper
      min-width: 20px
      height: 20px
      background: url('../asset/icon/sniper_s.png') no-repeat center
      background-size: cover
    
    &_sniper_2
      min-width: 20px
      height: 20px
      background: url('../asset/icon/sniper_2_s.png') no-repeat center
      background-size: cover
    
    &_sniper_api
      min-width: 20px
      height: 20px
      background-size: cover
      background: url('../asset/icon/sniper_s.png') no-repeat center
    
    &_instanavigation_person
      min-width: 20px
      height: 20px
      background: url('../asset/icon/instanavigation_person_s.png')

    &_whatsapp
      min-width: 20px
      height: 20px
      background: url('../asset/sprite/social_S.png') -170px -50px

    &_googlePlus
      min-width: 20px
      height: 20px
      background: url('../asset/icon/googlePlus_S.png')

    &_pluto
      min-width: 20px
      height: 20px
      background: url('../asset/icon/pluto.png') no-repeat center
      background-size: cover

    &_email
      min-width: 20px
      height: 20px
      background-size: contain
      background: url('../asset/icon/email.png')

    &_combined_email
      min-width: 20px
      height: 20px
      background-size: contain
      background: url('../asset/icon/email.png')

    &_phone
      min-width: 20px
      height: 20px
      background-size: contain
      background: url('../asset/icon/phone.png')

    &_combined_phone
      min-width: 20px
      height: 20px
      background-size: contain
      background: url('../asset/icon/phone.png')

    &_facebookid
      min-width: 20px
      height: 20px
      background-size: contain
      background: url('../asset/icon/facebookid.png')

    &_telegramid
      min-width: 20px
      height: 20px
      background-size: contain
      background: url('../asset/icon/telegramid.png')

    &_password
      min-width: 20px
      height: 20px
      background-size: contain
      background: url('../asset/icon/password.png')

    &_linkedinid
      min-width: 20px
      height: 20px
      background-size: contain
      background: url('../asset/icon/linkedinid.png')

    &_vkid
      min-width: 20px
      height: 20px
      background-size: contain
      background: url('../asset/icon/vkid.png')

    &_fullname
      min-width: 20px
      height: 20px
      background-size: contain
      background: url('../asset/icon/fullname.png')

    &_name
      min-width: 20px
      height: 20px
      background-size: contain
      background: url('../asset/icon/fullname.png')

    &_combined_name
      min-width: 20px
      height: 20px
      background-size: contain
      background: url('../asset/icon/fullname.png')
    
    &_twitterid
      min-width: 20px
      height: 20px
      background-size: contain
      background: url('../asset/icon/twitterid.png')

    &_instagramid
      min-width: 20px
      height: 20px
      background-size: contain
      background: url('../asset/icon/instagramid.png')

    &_irbis_eye
      min-width: 20px
      height: 20px
      background-size: cover
      background: url('../asset/icon/irbis_eye_S.png')

    &_summary
      min-width: 20px
      height: 20px
      background-size: cover
      background: url('../asset/icon/summary_s.png')
    
    &_psych
      min-width: 20px
      height: 20px
      background-size: cover
      background: url('../asset/icon/psych_s.png')

    &_quotaprofiler-lookup
      min-width: 20px
      height: 20px
      background: url('../asset/icon/quotaprofiler-lookup.png')

    &_quotaprofiler-crawler
      min-width: 20px
      height: 20px
      background: url('../asset/icon/quotaprofiler-crawler.png')

    &_quotaprofiler-cases
      min-width: 20px
      height: 20px
      background: url('../asset/icon/quotaprofiler-cases.png')
    
    &_quotawebint-crawler
      min-width: 20px
      height: 20px
      background: url('../asset/icon/quotawebint-crawler.png')

    &_quotawebint-cases
      min-width: 20px
      height: 20px
      background: url('../asset/icon/quotawebint-cases.png')
    
    &_quotawebint-monitoring
      min-width: 20px
      height: 20px
      background: url('../asset/icon/quotawebint-monitoring.png')

    &_face_search
      width: 30px
      height: 30px
      background-size: cover!important
      background: url('../asset/icon/face_search.png')
    &_photo_searcher
      width: 30px
      height: 30px
      background-size: cover!important
      background: url('../asset/icon/face_search.png')

    &_iafd
      width: 16px
      height: 16px
      background-size: cover!important
      background: url('../asset/icon/iafd.png')

    &_flickr
      width: 16px!important
      height: 16px
      background-size: cover!important
      background: url('../asset/icon/flickr.svg')

    &_web
      width: 16px!important
      height: 16px!important
      background-size: cover!important
      background: url('../asset/icon/link.svg')
    
    &_webmii
      min-width: 20px
      height: 20px
      background: url('../asset/icon/webmii_s.png')
    
    &_eyes
      min-width: 20px
      height: 20px
      background: url('../asset/icon/eyes_s.png') no-repeat center
      background-size: cover

    &_github
      min-width: 20px
      height: 20px
      background: url('../asset/icon/github.png')  no-repeat center
    
    &_country_fullname
      min-width: 20px
      height: 20px
      background: url('../asset/icon/country_fullname_s.png')
      background-size: cover
    
    &_geolocation_number
      min-width: 20px
      height: 20px
      background: url('../asset/icon/geolocation_number_s.png')
      background-size: cover
    
    &_email_domain_validator
      min-width: 20px
      height: 20px
      background: url('../asset/icon/email_domain_validator_s.png')
      background-size: cover
    
    &_mail_reveal
      min-width: 20px
      height: 20px
      background: url('../asset/icon/mail_reveal_s.png')
      background-size: cover

    &_name_by_country
      min-width: 20px
      height: 20px
      background: url('../asset/icon/country_fullname_s.png')
      background-size: cover


    &_duolingo
      width: 20px
      height: 20px
      background: url('../asset/icon/duolingo.png')  no-repeat center

    &_bitmoji
      width: 20px
      height: 20px
      background: url('../asset/icon/bitmoji.png')  no-repeat center
      background-size: cover

    &_gravatar
      width: 20px
      height: 20px
      background: url('../asset/icon/gravatar.png')  no-repeat center
      background-size: cover

    &_x
      width: 20px
      height: 20px
      background: url('../asset/icon/x.png')  no-repeat center
      background-size: cover

    &_protonmail
      width: 20px
      height: 20px
      background: url('../asset/icon/protonmail.png')  no-repeat center

    &_imgur
      width: 20px
      height: 20px
      background: url('../asset/icon/imgur.png')  no-repeat center

    &_mailru
      width: 20px
      height: 20px
      background: url('../asset/icon/mailru.png')  no-repeat center
     
  &__large
    &_callapp
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -10px -10px

    &_drupe
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -60px -10px

    &_eyecon
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -10px -60px

    &_fbProvider
      min-width: 10px
      height: 20px
      background: url('../asset/sprite/social_L.png') -10px -210px

    &_fb
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -60px -60px

    &_getcontact
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -110px -10px

    &_googleProvider
      min-width: 21px
      height: 21px
      background: url('../asset/sprite/social_L.png') -210px -155px

    &_hiya
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -110px -60px

    &_imo
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -10px -110px


    &_instagram
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -60px -110px

    &_linkedin
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -110px -110px

    &_ok
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -160px -10px

    &_microsoftProvider
      min-width: 24px
      height: 25px
      background: url('../asset/sprite/social_L.png') -210px -110px

    &_skype
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -160px -60px

    &_tamtam
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -160px -110px

    &_botim
      width: 30px
      height: 30px
      background: url('../asset/icon/botim_L.png')
    
    &_luna
      width: 30px
      height: 30px
      background: url('../asset/icon/luna_l.png')
    
    &_taro
      width: 30px
      height: 30px
      background: url('../asset/icon/taro_l.png')
    
    &_kyc_iban
      width: 30px
      height: 30px
      background: url('../asset/icon/kyc_iban_l.png')
    
    &_kyc_vat_validate
      width: 30px
      height: 30px
      background: url('../asset/icon/kyc_vat_validate_l.png')
    
    &_web_collection_screenshot
      width: 30px
      height: 30px
      background: url('../asset/icon/web_collection_screenshot_l.png')
    
    &_web_collection_scrape
      width: 30px
      height: 30px
      background: url('../asset/icon/web_collection_scrape_l.png')
    
    &_geolocation
      width: 30px
      height: 30px
      background: url('../asset/icon/geolocation_l.png')
    
    &_web_collection_phone
      width: 30px
      height: 30px
      background: url('../asset/icon/web_collection_phone_l.png')

    &_viewcaller
      width: 30px
      height: 30px
      background: url('../asset/icon/viewcaller_l.png')
    
    &_kraken
      width: 30px
      height: 30px
      background: url('../asset/icon/caller_api_l.png')
    
    &_web_collection_email
      width: 30px
      height: 30px
      background: url('../asset/icon/web_collection_email_l.png')

    &_telegram
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -10px -160px

    &_tc
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -60px -160px

    &_twitter
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -110px -160px

    &_viber
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -160px -160px

    &_vk
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -210px -10px

    &_whatsapp
      min-width: 30px
      height: 30px
      background: url('../asset/sprite/social_L.png') -210px -60px

    &_pluto
      min-width: 30px
      height: 30px
      background: url('../asset/icon/pluto.png') no-repeat center
      background-size: cover

    &_tiktok
      width: 30px
      height: 30px
      background: url('../asset/icon/tiktok.png') center no-repeat
      background-size: cover

    &_blsk
      width: 30px
      height: 30px
      background: url('../asset/icon/blsk_l.png') center no-repeat
      background-size: cover

    &_sniper
      min-width: 30px
      height: 30px
      background: url('../asset/icon/sniper_l.png') no-repeat center
      background-size: cover
    
    &_sniper_2
      width: 30px
      height: 30px
      background: url('../asset/icon/sniper_2_l.png') center no-repeat
      background-size: cover

    &_sniper_api
      min-width: 30px
      height: 30px
      background-size: cover
      background: url('../asset/icon/sniper_s.png') no-repeat center

    &_irbis_eye
      width: 30px
      height: 30px
      background: url('../asset/icon/irbis_eye.png') center no-repeat
      background-size: cover
    
    &_summary
      min-width: 20px
      height: 20px
      background-size: cover
      background: url('../asset/icon/summary_l.png')
    
    &_psych
      min-width: 20px
      height: 20px
      background-size: cover
      background: url('../asset/icon/psych_l.png')
    
    &_instanavigation_person
      min-width: 30px
      height: 30px
      background: url('../asset/icon/instanavigation_person_l.png')
    
    &_webmii
      min-width: 30px
      height: 30px
      background: url('../asset/icon/webmii_l.png')
    
    &_eyes
      min-width: 30px
      height: 30px
      background: url('../asset/icon/eyes_l.png') no-repeat center
      background-size: cover
    
    &_country_fullname
      min-width: 30px
      height: 30px
      background: url('../asset/icon/country_fullname_l.png')
      background-size: cover

    &_geolocation_number
      min-width: 30px
      height: 30px
      background: url('../asset/icon/geolocation_number_l.png')
      background-size: cover
    
    &_email_domain_validator
      min-width: 30px
      height: 30px
      background: url('../asset/icon/email_domain_validator_l.png')
      background-size: cover
    
    &_mail_reveal
      min-width: 30px
      height: 30px
      background: url('../asset/icon/mail_reveal_l.png')
      background-size: cover

.common
  &__comment
    min-width: 16px
    height: 16px
    background: url("../asset/sprite/comment.png") 

  &__copy
    min-width: 20px
    height: 20px
    background: url("../asset/sprite/common.png") -10px -217px

  &__delete
    min-width: 20px
    height: 20px
    background: url("../asset/sprite/common.png") -50px -217px

  &__deleteNew
    min-width: 14px
    height: 14px
    background: url("../asset/img/x.png")

  &__download
    min-width: 18px
    height: 18px
    background: url("../asset/sprite/common.png") -210px -217px

  &__downloadNew
    min-width: 20px
    height: 18px
    background: url("../asset/img/download.png")
  
  &__downloadSvg 
    min-width: 24px
    height: 24px
    background: url("../asset/img/download.svg") 
    
  &__hide
    min-width: 20px
    height: 20px
    background: url("../asset/sprite/common.png") -90px -217px

  &__info
    min-width: 12px
    height: 12px
    background: url("../asset/sprite/common.png") -272px -82px

  &__like
    min-width: 16px
    height: 16px
    background: url("../asset/sprite/common.png") -272px -46px

  &__logoAuthIrbis
    min-width: 333px
    height: 136px
    background: url("../asset/sprite/irbis_white_b.png") -10px -10px
  
  &__logoAuthClearcheck
    min-width: 333px
    height: 136px
    background: url("../asset/sprite/irbis_circle_yellow_l.svg") -10px -10px no-repeat
    background-size: contain

  &__logoMain
    min-width: 231px
    height: 112px
    background: url("../asset/sprite/irbis_white_s.png") -10px -10px

  &__notification
    min-width: 30px
    height: 30px
    background: url("../asset/sprite/common.png") -169px -87px

  &__popoverArrLeft
    min-width: 20px
    height: 22px
    background: url("../asset/sprite/common.png") -162px -140px

  &__popoverArrRight
    min-width: 20px
    height: 22px
    background: url("../asset/sprite/common.png") -202px -140px

  &__search
    min-width: 12px
    height: 12px
    background: url("../asset/sprite/common.png") -272px -114px

  &__share
    min-width: 20px
    height: 20px
    background: url("../asset/sprite/common.png") -130px -217px

  &__show
    min-width: 20px
    height: 20px
    background: url("../asset/sprite/common.png") -170px -217px

  &__stepArrLeft
    min-width: 56px
    height: 56px
    background: url("../asset/sprite/common.png") -86px -140px

  &__stepArrRigth
    min-width: 56px
    height: 57px
    background: url("../asset/sprite/common.png") -10px -140px

  &__user
    min-width: 30px
    height: 30px
    background: url("../asset/sprite/common.png") -219px -87px

  &__export
    width: 25px
    height: 20px
    background: url("../asset/sprite/export.png")

  &__similarity
    width: 30px
    height: 30px
    background: url("../asset/icon/similarity.png")

  &__similarity_white
    width: 30px
    height: 30px
    background: url("../asset/icon/similarity_white.png")

  &__compare
    width: 40px
    height: 40px
    background: url("../asset/icon/compare.png")

  &__sentiment_positive
    width: 80px
    height: 80px
    background: url("../asset/sprite/positive.png") no-repeat center
    background-size: contain
    
  &__sentiment_negative
    width: 80px
    height: 80px
    background: url("../asset/sprite/negative.png") no-repeat center
    background-size: contain

  &__sentiment_neutral
    width: 80px
    height: 80px
    background: url("../asset/sprite/neutral.png") no-repeat center
    background-size: contain