@import "variable"

.title
  padding: 15px
  font-family: $fontBold
  background: #f7fbff


.api
  padding: 4px
  width: 100%
  line-height: 36px

  &__method
    width: 104px
    line-height: 36px
    text-align: center
    color: #FFFFFF
    border-radius: 4px

    &_get
      @extend .api__method
      background: #4B7FF0

    &_post
      @extend .api__method
      background: #65B994

    &_delete
      @extend .api__method
      background: #F55E4B

    &_put
      @extend .api__method
      background: #F9A74A

  &__icon
    display: flex
    align-items: center
    margin-left: auto !important
    height: 36px

  &_get
    @extend .api
    background: #ECF1FC

  &_post
    @extend .api
    background: #EBF3F0

  &_delete
    @extend .api
    background: #F9E9E8

  &_put
    @extend .api
    background: #FBF2EA