@import 'variable'

@font-face
  font-family: Montserrat-Light
  src: url(../asset/font/Montserrat-Light-300.ttf) format("truetype")

@font-face
  font-family: Montserrat-Regular
  src: url(../asset/font/Montserrat-Regular-400.ttf) format("truetype")

@font-face
  font-family: Montserrat-SemiBold
  src: url(../asset/font/Montserrat-SemiBold-600.ttf) format("truetype")

@font-face
  font-family: Montserrat-Bold
  src: url(../asset/font/Montserrat-Bold-700.ttf) format("truetype")
  font-weight: 700

@font-face
  font-family: Montserrat-Black
  src: url(../asset/font/Montserrat-Black-900.ttf) format("truetype")

@font-face
  font-family: Inter
  src: url(../asset/font/Inter.ttf) format("truetype")


.rtl
  direction: rtl

.header
  padding: 20px
  background: #FFF
  .headerPlan
    @media screen and (max-width: $breakpointSm)
      padding: 0 14px

.menu
  padding: 24px 5px 24px 20px
  width: unset !important
  height: 91px

  &__logoIrbis
    width: 200px
    height: 75px
    background: url("../asset/sprite/irbis_white_s.png") -4px -7px
    transition: all 0.3s
    cursor: pointer

    &_inactive
      @extend .menu__logoIrbis
      transform: scale(0)

  &__logoClearcheck
    width: 200px
    height: 70px
    background: url("../asset/sprite/irbis_circle_yellow_l.svg") 50px 0px no-repeat
    transition: all 0.3s
    cursor: pointer
    background-size: contain

    &_inactive
      @extend .menu__logoClearcheck
      transform: scale(0) 

  &__devIcon
    display: flex
    justify-content: center
    align-items: center
    width: 21px
    height: 21px
    border-radius: 7px
    background: dimgrey

    &_active
      @extend .menu__devIcon
      background: lightgrey

    &__inner
      font-size: 11px
      line-height: 21px
      color: lightgrey

      &_active
        @extend .menu__devIcon__inner
        color: #161624


.form
  &__phone
    input
      padding: 11px 8px
      width: 100%
      height: 42px
      font-size: 14px
      font-family: $fontRegular
      background-color: #EFF0F6
      border-color: rgba(0, 0, 0, 0.23)
      border-width: 1px
      border-radius: 5px
      box-sizing: border-box

      &::placeholder
        color: #D6D7E3

      &:hover
        border: 1px solid #000

      &:focus
        border: 2px solid $colorPrimary
        outline: none
        background-color: #FBFBFF

    &_error
      @extend .form__phone

      input
        background: #FFF5F5
        border-width: 1px
        border-color: $colorError

        &::placeholder
          color: $colorError

.form
  &__phoneinline
    border-color: transparent
    border-radius: 0!important
    &:hover
      border: 1px transparent
    input
      padding: 11px 8px
      width: 100%
      height: 42px
      font-size: 14px
      font-family: $fontRegular
      background-color: #F5F8FF
      border-color: transparent
      border-width: 1px
      //border-radius: 5px
      box-sizing: border-box

      &::placeholder
        //color: black
        font-weight: 400

      &:hover
        border: 1px solid $colorPrimary

      &:focus
        border: 2px solid $colorPrimary
        outline: none
        //background-color: #F5F8FF

    .MuiOutlinedInput-notchedOutline
      border-color: transparent
      border-radius: 0
      display: none
      &:hover
        border: 1px solid $colorPrimary

    &.MuiOutlinedInput-root
      border: 1px solid transparent
      &:hover
        border: 1px solid transparent
        .MuiOutlinedInput-notchedOutline
          border: 1px solid transparent

    &_error
      @extend .form__phoneinline
      //border: 1px solid $colorError

      &:hover
        border-color: $colorError

      .MuiOutlinedInput-notchedOutline
        border-radius: 0
        border-width: 1px
        border-color: $colorError
        &:hover
          border-radius: 0
          border-width: 2px
          border-color: $colorError

      input
        background: #FFF5F5
        border: 1px solid $colorError
        outline: none

        &:hover
          border-color: $colorError

        &::placeholder
          color: $colorError

        &:focus
          border: 2px solid $colorError
          outline: none

      &.MuiOutlinedInput-root
        &:hover
          .MuiOutlinedInput-notchedOutline
            border-color: $colorError

  &__textarea
    padding: 8px
    font-family: $fontRegular
    font-size: 16px
    background-color: #EFF0F6
    border: 2px solid transparent
    border-radius: 4px
    resize: none
    box-sizing: border-box
    outline: 1px solid #C9C9C9

    &::placeholder
      font-size: 14px
      font-family: $fontRegular
      color: #a3a3a3

    &:focus
      border: 2px solid $colorPrimary
      outline: none !important
      background-color: #FBFBFF

    &:hover
      outline: 1px solid #000

    &_error
      @extend .form__textarea
      background: #FFF5F5
      outline: 1px solid $colorError

      &::placeholder
        color: $colorError

  &__dropzone
    display: flex
    justify-content: center
    align-items: center
    height: 68px
    cursor: pointer
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='gray' stroke-width='1' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e")


.button
  &__close
    position: absolute !important
    right: 8px
    top: 8px
    color: $colorSecondaryLight,


.grecaptcha-badge
  visibility: hidden

.MuiAccordionSummary-content
  margin: 0 !important

.notification
  width: 400px
  max-height: calc(100vh - 88px)
  overflow-y: auto
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important

  &__title
    text-overflow: ellipsis
    overflow: hidden

  &__text
    height: 40px
    white-space: break-spaces
    overflow: hidden

.scrollNotification 
  overflow-x: hidden !important

.links 
  margin-bottom: 40px !important


._noBreak 
  break-inside: avoid
  display: block

.content
  @media screen and (max-width: $breakpointSm)
    padding: 20px!important

.custom-scroll::-webkit-scrollbar
  width: 4px
  height: 5px

.custom-scroll::-webkit-scrollbar-track
  background-color: rgba(255, 255, 255, 0.1)
  border-radius: 2px

.custom-scroll::-webkit-scrollbar-thumb
  background-color: #ADBACB
  border-radius: 2px