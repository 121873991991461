@import "variable"

.domains
  padding: 5px
  min-height: 50px
  background: #EFF0F6
  border: 1px solid rgba(0, 0, 0, 0.23)
  border-radius: 5px

  &__chips
    flex-direction: row !important
    flex-wrap: wrap
    padding: 8px 8px 0
    background: #EFF0F6

  .input
    position: relative

    &__field
      width: 100%
      font-size: 15px
      font-family: $fontRegular
      border: none
      outline: none
      background: transparent

    &__hint
      position: absolute
      padding: 3px
      border-radius: 3px
      background: #FFFFFF
      box-shadow: 0 0 3px gray
      cursor: pointer

      &:hover
        background: $colorSecondaryLight
