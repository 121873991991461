@import "variable"

.ticket
  width: 16px
  height: 16px
  border-radius: 50%


.avatar
  &__bg
    display: flex
    justify-content: center
    align-items: center
    min-width: 32px
    height: 32px
    background: lightgray
    border-radius: 50%


.preview
  padding: 0
  width: 156px !important
  height: 125px !important
  overflow: hidden
  background: lightgray !important

  &:hover
    background: grey !important

  &__nav
    position: absolute
    top: 5px
    right: 5px
    z-index: 1

  &__navBtn
    padding: 0 !important
    min-width: 0 !important

  &__img
    position: absolute
    top: 0
    width: 100%
    height: 100%
    object-fit: contain

    &:hover
      opacity: 0.5


  &__info
    width: 156px
    height: 36px
    background: #FFFFFF
    opacity: 0.4

  &__text
    position: absolute
    bottom: 6px
    left: 10px
    right: 5px
    width: 146px
    overflow: hidden
    text-overflow: ellipsis
    color: #000000
