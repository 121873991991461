.defaultAvatar
  display: flex
  justify-content: center
  align-items: center
  margin-bottom: 2rem
  width: 200px
  height: 200px
  background: #EFF0F6

  svg
    width: 100px
    height: 100px
    color: #D6D7E3
