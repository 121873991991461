@import "variable"

.root
  position: absolute
  top: 115%
  padding: 38px
  text-align: center
  width: 500px
  background: #FFFFFF
  border-radius: 20px
  z-index: 2

  &_right
    @extend .root
    left: 105%
    &::before
      position: absolute
      content: ""
      top: -12px
      left: -12px
      width: 56px
      height: 56px
      background: url("../asset/sprite/common.png") -86px -140px

  &_left
    @extend .root
    right: 105%
    &::before
      position: absolute
      content: ""
      top: -13px
      right: -6px
      width: 56px
      height: 57px

      background: url("../asset/sprite/common.png") -10px -140px


.listDot
  margin-right: 10px
  font-size: 13px !important

  &_primary
    @extend .listDot
    color: $colorPrimary

  &_secondary
    @extend .listDot
    color: #D1EC2C

