@import "variable"

.root
  padding: 1rem

.title
  margin-top: 46px
  text-align: center
  font-size: 24px
  font-family: $fontSemiBold


.icon
  width: 250px
  height: 250px
  background-size: 100% 100%

  &_success
    @extend .icon
    background-image: url("../asset/img/paymentSuccess.svg")

  &_fail
    @extend .icon
    background-image: url("../asset/img/paymentFail.svg")


.text
  text-align: center
  font-size: 12px
  color: #747474

.popup
  position: fixed
  top: 0
  left: 0
  margin: 0 !important
  width: 100%
  height: 100%
  z-index: 9999

  &__close
    position: absolute !important
    right: 0
    padding: 0 !important
    height: 100% !important
    width: 100px

    &:hover
      background: none!important

  &__iframe
    
    width: 100%
    height: 100%
    border: none
    overflow-x: hidden
    overflow-y: auto
    .container 
      max-width: 1000px !important

.info
  &__text
    font-size: 18px