@import "variable"

.root
  width: 100vw
  min-height: 100vh
  background: #FFFFFF

  @media screen and (max-width: $breakpointMd)
    height: 75vh

.infoIrbis
  padding: 50px
  color: #FFF
  background: $colorTitle


.info
  padding: 50px
  color: #FFF
  background: $colorTitle

  @media screen and (max-width: $breakpointMd)
    height: 165px

  &__logoIrbis
    margin-bottom: 40px
    image-rendering: pixelated
    width: 333px
    height: 110px
    background: url("../asset/sprite/irbis_white_b.png") 8px 10px
    box-sizing: border-box
    cursor: pointer
  
  &__logoClearcheck
    margin-bottom: 40px
    image-rendering: pixelated
    width: 333px
    height: 110px
    background: url("../asset/sprite/irbis_circle_yellow_l.svg") 120px no-repeat
    background-size: contain
    box-sizing: border-box
    cursor: pointer

  &__title
    font-family: $fontSemiBold
    font-size: 16px

    &_price
      @extend .info__title
      color: #AFB6C3

  &__text
    font-size: 13px


.content
  @media screen and (max-width: $breakpointMd)
    padding: 0 20px

  @media screen and (min-width: $breakpointMd)
    margin: 80px 130px
    max-width: 400px


.title
  font-family: $fontBold
  font-size: 30px


.hint
  font-size: 14px

  &_light
    @extend .hint
    color: $colorHintLight

  &_large
    font-size: 16px

  &_info
    padding: 5px 10px
    font-size: 16px
    color: #FFFFFF
    background: $colorPrimary
    border-radius: 4px

  &_small
    line-height: 15px
    font-size: 10px
    color: #171717


.or
  display: flex
  align-items: center

  hr
    width: 100%
    border: 1px solid #F5F5F5

  span
    padding: 0 34px
    color: #BABABA

