@import "variable"
.root
  min-height: calc(100vh - 86px)
  transition: margin-left 0.3s ease
  box-sizing: border-box

  &_ltr
    margin-left: auto

  &_rtl
    margin-right: auto


.root_mobile.root_ltr
  @extend .root

  //margin-left: 64px

.root_mobile.root_rtl
  @extend .root

  //margin-right: 60px

.root_wide.root_ltr
  @extend .root

  @media (min-width: $breakpointMd)
    margin-left: 64px

.root_wide.root_rtl
  @extend .root
  margin-right: 64px

.root_narrow.root_ltr
  @extend .root
  margin-left: 260px

.root_narrow.root_rtl
  @extend .root
  margin-right: 240px
